









































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarFinanceReports from "@/components/sidebar-finance-reports.vue";
import ReportService from "@/services/reports-service";
import accountService from '@/services/account-service';
@Component({
  components: {
    Header,
    SidebarFinanceReports,
  },
})

export default class TrialBalance extends Vue {

  public filter = null;
  public filterOn = [];
  trans = [];
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  selected_report_date_from:any = '';
  selected_report_date_to :any  = '';
  public options_account = []
  public account_selected: any = {}
  side_tran_entry = [];
  side_tran = {};
  get rows() {
    return this.selected_items.length;
  }
  public items = [];

public fields = [
    {
      key: "account_title",
    },
    {
      key: "opening_debit",
    },
    {
      key: "opening_credit",
    },

    {
      key: "total_debit",
    },
     {
      key: "total_credit",
    },
    {
      key: "closing_debit",
    },
    {
      key: "closing_credit",
    },
    
  ];
  public selected_items = [
    {
      account_title: "",
      debit: 0,
      credit: 0,
      balance: "",    
      date: "",
    },
  ];

  retrieve() {
    this.selected_items = [];
    ReportService.trailbalance()
      .then((response) => {        
        if(response.data.length > 0) {
          this.selected_items = response.data;
        }else{
          this.selected_items.push(
            {
              account_title: "No Record Found",
              date: "", 
              debit : 0, 
              credit: 0, 
              balance: ""
            },
          )
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }


  mounted() {
    this.retrieve()
  }

  submit() {
    this.retrieve()
    //  let obj = {
      // start_date : this.selected_report_date_from,
      // end_date : this.selected_report_date_to,
    //   project : this.account_selected.id,
    // }
    //   this.retrieve(obj);    
  }

  onRowClicked(item: any, index: any, event: any) {
    ReportService.get_transaction_id(item.transaction_id)
      .then((response) => {
        this.side_tran = response.data.transaction[0]
        this.side_tran_entry = response.data.entry
      })
      .catch((e) => {
        console.log(e);
      });
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant");
  }
  


  get_parent_account(data: any){      
    this.account_selected = data
    this.options_account = [];    
  }

  search_parent_account(title: any){    
    if(title.length > 4){
      accountService.getByTitle(title)
      .then((response) => {  
        this.options_account = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){
        this.options_account = [];
    }    
  }

  
}
